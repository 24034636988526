import { useAuthenticationService, usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { v4 as uuid4 } from 'uuid';

import { VISITS_FEATURE_NAME } from '../../consts/AnalyticsConsts';
import { CommonSegmentProp, ComponentsName } from '../../types/analyticsTypes';

export const useAnalytics = () => {
  const operationData = localStorage.getItem('@operation_2.0.0') || '{}';
  const { operation } = JSON.parse(operationData);
  const { vendorId, seller, organization, country } = operation || {};

  const authentication = useAuthenticationService();
  const { language: deviceLanguage } = authentication.getUserCountryAndLanguage();
  const { preferredLanguage } = usePreferredLanguageV2();
  const email = authentication.getUserEmailB2C();
  const ddcData = localStorage.getItem('@ddc_1.0.0') || '{}';
  const { ddc } = JSON.parse(ddcData);

  const defaultProps = {
    [CommonSegmentProp.country]: country?.countryCode,
    [CommonSegmentProp.countryVendorGroup]: country?.countryCode,
    [CommonSegmentProp.componentName]: ComponentsName.sidebar,
    [CommonSegmentProp.ddc]: ddc?.id,
    [CommonSegmentProp.deviceLanguage]: deviceLanguage,
    [CommonSegmentProp.email]: email,
    [CommonSegmentProp.userSelectedLanguage]: preferredLanguage,
    [CommonSegmentProp.vendorGroupId]: seller?.id ?? organization?.id,
    [CommonSegmentProp.vendorId]: vendorId,
    [CommonSegmentProp.sessionId]: uuid4(),
    [CommonSegmentProp.featureName]: VISITS_FEATURE_NAME,
  };

  return {
    defaultProps,
  };
};
