import { ScreenNameApp } from '../config/typewriter';

export enum CommonSegmentProp {
  ddc = 'ddc',
  country = 'country',
  countryVendorGroup = 'country_vendorGroup',
  componentName = 'component_name',
  deviceLanguage = 'device_language',
  email = 'email',
  userSelectedLanguage = 'user_selected_language',
  vendorGroupId = 'vendor_group_id',
  vendorId = 'vendor_id',
  sessionId = 'session_id',
  userGroups = 'user_groups',
  screenNameApp = 'screen_name_app',
  featureName = 'feature_name',
}

export enum ComponentsName {
  sidebar = 'menu_sidebar_collapsed',
}

export enum ScreenOrigin {
  tasks = 'Tasks',
  visits = 'Visit',
  operationpanel = 'Operation panel',
  taskgroups = 'Task groups',
  audiences = 'Audience builder',
}

export enum UseSegmentSidebarMenuClickedProps {
  screenOrigin = 'screen_origin',
  buttonName = 'button_name',
  failureReason = 'failure_reason',
  originScreenName = 'origin_screen_name',
  unknownError = 'unknown_error',
}

export enum SidebarMenuClickedSegmentProps {
  screenOrigin = 'visits',
  buttonName = 'menu_visits',
  failureReason = 'failure_reason',
  originScreenName = 'visits',
}

export enum buttonName {
  menuTasks = 'menu_tasks',
  menuVisits = 'menu_visits',
  menuTaskGroups = 'menu_task_groups',
  menuOperationPanel = 'menu_operation_panel',
  menuAudienceBuilder = 'menu_audience_builder, menu_task_group',
}

export interface CommonSegmentPropsState {
  [CommonSegmentProp.country]: string | null;
  [CommonSegmentProp.countryVendorGroup]: string | null;
  [CommonSegmentProp.componentName]: string;
  [CommonSegmentProp.ddc]: string;
  [CommonSegmentProp.deviceLanguage]: string;
  [CommonSegmentProp.email]: string;
  [CommonSegmentProp.screenNameApp]: ScreenNameApp;
  [CommonSegmentProp.userSelectedLanguage]: string;
  [CommonSegmentProp.vendorGroupId]: string;
  [CommonSegmentProp.vendorId]: string;
  [CommonSegmentProp.featureName]: string;
  [CommonSegmentProp.userGroups]: string[];
  [CommonSegmentProp.sessionId]: string;
}

export interface UseSegmentSidebarMenuClickedPropsState {
  [UseSegmentSidebarMenuClickedProps.buttonName]: buttonName;
  [UseSegmentSidebarMenuClickedProps.screenOrigin]: string;
}
