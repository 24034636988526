import MessageMap from '../i18n.d';

const es: MessageMap = {
  titleSwitch: {
    title: '¿Qué te gustaría acceder?',
  },
  cardSwitch: {
    title: {
      taskManagement: 'Géstion de tareas',
      OperationsPanel: 'Operation panel',
      taskGroups: 'Grupos de tareas',
      userCreation: 'Creación de usuario',
      audienceBuilder: 'Creador de audiencia',
      visits: 'Gestión de visitas',
    },
    description: {
      taskManagement: 'Crear y administrar tareas',
      OperationsPanel: `Search by BDRs, accounts, visits, and tasks in Force's database.`,
      taskGroups: 'Crear, editar y priorizar grupos de tareas.',
      userCreation:
        'Crear cuentas para BEES One y Force para gestionar eficazmente el flujo de trabajo de los usuarios.',
      audienceBuilder: 'Crea audiencias objetivo usando filtros o listas predefinidas.',
      visits: 'Ver y administrar visitas.',
    },
  },
  sidebar: {
    title: {
      pageTasks: 'Gestión de tareas',
      pageOperations: 'Panel de operaciones',
      pageTaskGroups: 'Grupos de tareas',
      pageAudienceBuilder: 'Creador de audiencia',
      pageVisits: 'Visitas',
    },
  },
  test: {
    translate: 'Traducción',
  },
};

export default es;
