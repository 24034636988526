import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  titleSwitch: {
    title: 'What would you like to access?',
  },
  cardSwitch: {
    title: {
      taskManagement: 'Task management',
      OperationsPanel: 'Operation panel',
      taskGroups: 'Task groups',
      userCreation: 'User creation',
      audienceBuilder: 'Audience builder',
      visits: 'Visits management',
    },
    description: {
      taskManagement: 'Create and manage BDR tasks.',
      OperationsPanel: `Search by BDRs, accounts, visits, and tasks in Force's database.`,
      taskGroups: 'Create, edit, and prioritize task groups.',
      userCreation: 'Create accounts for BEES One and Force to effectively manage users workflow.',
      audienceBuilder: 'Create target audiences using filters or predefined lists.',
      visits: 'View and manage visits.',
    },
  },
  sidebar: {
    title: {
      pageTasks: 'Tasks management',
      pageOperations: 'Operation panel',
      pageTaskGroups: 'Task groups',
      pageAudienceBuilder: 'Audience builder',
      pageVisits: 'Visits',
    },
  },
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
};

export default enUS;
