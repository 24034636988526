import { AnalyticsWrapper } from '@bees-force-shared/business-components';
import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import './global.css';
import { EnvConfig, EnvProvider } from './providers/EnvProvider';
import { IntlProvider } from './providers/IntlProvider';
import OptimizelyProvider from './providers/OptimizelyProvider';
import Router from './Router';

export default function App(props: EnvConfig): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-force-switch-mfe',
    seed: 'bees-hub-force-switch-mfe',
  });

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <OptimizelyProvider>
            <AnalyticsWrapper segmentKey={props.SEGMENT_KEY ?? ''}>
              <Router />
            </AnalyticsWrapper>
          </OptimizelyProvider>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
