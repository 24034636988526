import analytics, { ScreenNameApp } from '../../config/typewriter';
import { routeMapping } from '../../consts/AnalyticsConsts';
import {
  CommonSegmentProp,
  ScreenOrigin,
  UseSegmentSidebarMenuClickedProps,
  UseSegmentSidebarMenuClickedPropsState,
} from '../../types/analyticsTypes';
import { useAnalytics } from '../useAnalytics/useAnalytics';

export const useSegmentSidebarMenuClicked = (): {
  dispatchSidebarMenuClickedEvent: (props: UseSegmentSidebarMenuClickedPropsState) => void;
} => {
  const { defaultProps } = useAnalytics();
  const getScreenOrigin = (route: string): ScreenOrigin | undefined => {
    return routeMapping[route];
  };

  const dispatchSidebarMenuClickedEvent = ({
    button_name: buttonName,
    screen_origin: screenOrigin,
  }: UseSegmentSidebarMenuClickedPropsState) => {
    const sidebarMenuClickedProps = {
      ...defaultProps,
      [CommonSegmentProp.screenNameApp]: screenOrigin as ScreenNameApp,
      [UseSegmentSidebarMenuClickedProps.buttonName]: buttonName,
      [UseSegmentSidebarMenuClickedProps.originScreenName]: getScreenOrigin(
        window.location.pathname
      ),
      [UseSegmentSidebarMenuClickedProps.failureReason]: '',
    };

    try {
      analytics.sideBarMenuClicked(sidebarMenuClickedProps);
    } catch (error) {
      analytics.sideBarMenuClicked({
        ...sidebarMenuClickedProps,
        [UseSegmentSidebarMenuClickedProps.failureReason]:
          error instanceof Error ? error.message : UseSegmentSidebarMenuClickedProps.unknownError,
      });
    }
  };

  return {
    dispatchSidebarMenuClickedEvent,
  };
};
