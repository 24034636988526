export enum Scopes {
  BeesForceOperationsGlobalRead = 'BeesForceAdmin.OperationsGlobal.Read',
  BeesForceVOneCardRead = 'BeesForceAdmin.VOneCard.Read',
  BeesForceVTwoCardRead = 'BeesForceAdmin.VTwoCard.Read',
  BeesForceTaskGroupsRead = 'BeesForceAdmin.TaskGroups.Read',
  BeesForceTaskGroupsWrite = 'BeesForceAdmin.TaskGroups.Write',
  BeesForceAdminInviteUser = 'IdentityFE.AdminUser.Invite',
  BeesForceTaskManagementRead = 'BeesForceAdmin.TaskManagement.Read',
  BeesForceTaskManagementWrite = 'BeesForceAdmin.TaskManagement.Write',
  BeesForceVisitsManagementWrite = 'BeesForceAdmin.VisitManager.Write',
  BeesForceNewMFECardRead = 'BeesForceAdmin.NewMFECard.Read',
  BeesForceNewMFETaskManagementRead = 'BeesForceAdmin.NewMFETaskManagement.Read',
  BeesForceNewMFETaskManagementWrite = 'BeesForceAdmin.NewMFETaskManagement.Write',
}
