import { ScreenOrigin } from '../types/analyticsTypes';

export const VISITS_FEATURE_NAME = 'visits';

export const routeMapping: Record<string, ScreenOrigin> = {
  '/force/visits': ScreenOrigin.visits,
  '/force/frontline/task-groups': ScreenOrigin.taskgroups,
  '/force/frontline/tasks': ScreenOrigin.tasks,
  '/force/task-management': ScreenOrigin.tasks,
  '/force/operations-panel': ScreenOrigin.operationpanel,
  '/audiences?origin=force': ScreenOrigin.audiences,
};
